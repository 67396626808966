import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import ContactUsSection from "../sections/contact-us";
import TutorialsSection from "../sections/tutorials";
import "./../common/styles.scss";
import { normalizePost } from "../helpers/normalizePost";


const ExplorePage = (props) => {
  const { formatMessage } = useIntl();

  const postsData = useStaticQuery(graphql`
    {
        posts: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 120}}}}}) {
            nodes {
              title
              link
              content
              databaseId
              date
              postsFields {
                featuredImage {
                  databaseId
                  mediaItemUrl
                }
              }
            }
      }
    }
  `);
  const posts = postsData.posts.nodes.map(normalizePost);

  return (
    <Layout location={props.location}>
      <TutorialsSection
        title={formatMessage({ id: "pages.explore.sections.main.title", defaultMessage: "Explore" })}
        sectionColor={SECTION_COLOR.White}
        titleColor={TITLE_COLORS.LightBlue}
        seeAllUrl="https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA"
        data={posts}
      >
        <Breadcrumbs
          items={[{ title: "Viewst", url: "/" }, { title: "Learn", url: "/learn/" }, {
            title: "Explore",
            url: "/explore/"
          }]}
        />
      </TutorialsSection>
      <ContactUsSection />
    </Layout>
  );
};

export default ExplorePage;
